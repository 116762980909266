let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-droopy-dev-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-droopy-dev-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://56oc5tq6b5ep5bdwwrzeahs35i.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://2ff5mukowa.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://caju1r7ht8.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.dev.droopy.forwoodsafety.com",
        WEBSOCKET: "wss://uvluzxok9f.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_XIn7YLlVP",
        IDENTITY_POOL_ID: "us-west-2:196b92be-f3e8-433b-85ae-d0e88ae4c215",
        USERPOOL_HOSTED_DOMAIN: "forwood-droopy-id-dev",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.dev.droopy.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.dev.droopy.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::431537927518:role/dev-CA"
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.3",
        HOSTNAME: "id.dev.droopy.forwoodsafety.com",
        COOKIE_DOMAIN: ".dev.droopy.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "f2304b11-1299-46eb-9b4f-2dd935c2c598",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.dev.droopy.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
